// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-flow-js": () => import("./../../../src/pages/flow.js" /* webpackChunkName: "component---src-pages-flow-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ordered-furniture-js": () => import("./../../../src/pages/ordered-furniture.js" /* webpackChunkName: "component---src-pages-ordered-furniture-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quality-js": () => import("./../../../src/pages/quality.js" /* webpackChunkName: "component---src-pages-quality-js" */),
  "component---src-pages-restaurant-interior-js": () => import("./../../../src/pages/restaurant-interior.js" /* webpackChunkName: "component---src-pages-restaurant-interior-js" */),
  "component---src-pages-shop-interior-js": () => import("./../../../src/pages/shop-interior.js" /* webpackChunkName: "component---src-pages-shop-interior-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-templates-work-detail-js": () => import("./../../../src/templates/work-detail.js" /* webpackChunkName: "component---src-templates-work-detail-js" */)
}

